import React from 'react';
import { Link } from 'react-router-dom';
import notfound from '../assets/error.svg';

export function Component() {
  return (
    <div className="min-h-screen w-full flex flex-col gap-10 px-2 lg:px-10 items-start">
      <img src="/img/logo.webp" className="w-20 pt-10" alt="" />
      <div className=" w-full flex flex-col justify-center items-center text-center py-10 dark:bg-slate-900">
        <img src={notfound} className="w-[20rem]" alt="" />

        <div className="max-w-[546px] mx-auto w-full mt-12">
          <h4 className="text-gray-800 font-bold dark:text-white text-[40px] leading-[50px] mb-4">
            oops!
          </h4>

          <div className="text-gray-800  dark:text-white text-2xl tracking-widest font-normal mb-10">
            Something went wrong
          </div>
        </div>

        <div className="max-w-max mx-auto w-full flex items-center gap-10  px-10">
          <Link
            to="#"
            className="btn bg-primary text-gray-50 rounded-lg py-2 dark:text-gary-800 dark:bg-white hover:bg-opacity-75 transition-all duration-150 block text-center px-6"
          >
            Get Support
          </Link>

          <Link
            to="/dashboard"
            className="btn bg-transparent text-black border-[.5px] border-black rounded-lg py-2 dark:text-gary-800 dark:bg-white hover:bg-opacity-75 transition-all duration-150 block text-center px-6"
          >
            Back to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}

Component.displayName = 'ErrorPage';
