import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedJob: null, // Job ID
};

const displaySlice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    setSelectedJob: (state, action) => {
      state.selectedJob = action.payload;
    },
    clearSelection: (state, action) => {
      state.selectedJob = null;
    },
  },
});

export const { setSelectedJob, clearSelection } = displaySlice.actions;

export default displaySlice.reducer;
