import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = 'https://successful-pink-tutu.cyclic.app/api/v1';
// const baseUrl = 'http://localhost:3000/api/v1';

const baseQuery = fetchBaseQuery({
  baseUrl,
  // credentials: 'include', // used for including cookies (if we use refresh tokens)
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) headers.set('Authorization', `Bearer ${token}`);
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: ['User', 'Member', 'Company', 'LinkedAccount', 'Package', 'Job', 'AppliedJob'],
  endpoints: (builder) => ({}),
});
