import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';
import { ToastContainer } from 'react-toastify';
// import ReactGA from 'react-ga';
// import Hotjar from '@hotjar/browser';
import App from './App';
import francheyeIcon from '@/assets/icons/francheye.png';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

// ReactGA.initialize('G-0SQBXPMJQY');

// const siteId = 3809731;
// const hotjarVersion = 6;

// Hotjar.init(siteId, hotjarVersion);

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer
          position="top-center"
          hideProgressBar
          icon={() => (
            <img
              src={francheyeIcon}
              alt="icon"
              className="min-w-10 max-w-10 p-1 rounded aspect-square bg-primary"
            />
          )}
        />
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>,
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
