import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Component as ErrorElement } from '@/pages/ErrorPage';

Sentry.init({
  dsn: 'https://abea60575406399dcfe6cc1d84153e9d@o4506892866879488.ingest.us.sentry.io/4506986448617472',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/francheye\.com/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  // Public landing page routes
  { path: '/', lazy: () => import('./pages/Home') },
  { path: '/creators', lazy: () => import('./pages/Creators') },
  { path: '/brands', lazy: () => import('./pages/Brands') },
  { path: '/privacy', lazy: () => import('./pages/Privacy') },
  { path: '/tc', lazy: () => import('./pages/TC') },

  // Public auth routes
  { path: '/login', lazy: () => import('./pages/Login') },
  { path: '/signup', lazy: () => import('./pages/Signup') },
  { path: '/forgot', lazy: () => import('./pages/ForgotPass') },
  { path: '/refsignup/:ref', lazy: () => import('./pages/ReferralSignup') },

  // Onboarding
  {
    lazy: () => import('./features/auth/ProtectedRoute'),
    errorElement: <ErrorElement />,
    children: [{ path: '/onboarding', lazy: () => import('./pages/Onboarding') }],
  },

  // Public site routes - restricted access
  {
    lazy: () => import('./layouts/BaseLayout'),
    errorElement: <ErrorElement />,
    children: [
      { path: '/leaderboard', lazy: () => import('./pages/Leaderboard') },
      { path: '/profile/:username', lazy: () => import('./pages/PublicProfile') },
      { path: '/brandinfo/:username', lazy: () => import('./pages/CompanyInfoPublic') },
      { path: '/comingsoon', lazy: () => import('./components/ComingSoon') },

      // Private site routes
      {
        lazy: () => import('./features/auth/ProtectedRoute'),
        errorElement: <ErrorElement />,
        children: [
          { path: '/dashboard', lazy: () => import('./pages/Dashboard') },
          { path: '/userinfo', lazy: () => import('./pages/UserInfo') },
          { path: '/portfolio', lazy: () => import('./pages/Portfolio') },
          { path: '/profile', lazy: () => import('./pages/Profile') },
          { path: '/settings', lazy: () => import('./pages/Settings') },
          { path: '/collections', lazy: () => import('./pages/Collections') },
          { path: '/brandinfo', lazy: () => import('./pages/CompanyInfoPrivate') },
          { path: '/company-settings', lazy: () => import('./pages/CompanySettings') },
          {
            path: '/jobs/:jobId?',
            lazy: () => import('./layouts/JobsLayout'),
            children: [
              { index: true, lazy: () => import('./pages/Marketplace') },
              { path: 'orders', lazy: () => import('./components/ComingSoon') },
              { path: 'myJobs', lazy: () => import('./pages/MyJobs') },
              { path: 'postedJobs', lazy: () => import('./pages/PostedJobs') },
            ],
          },
        ],
      },
    ],
  },

  // Error routes
  { path: '/error', lazy: () => import('./pages/ErrorPage') },
  { path: '*', lazy: () => import('./pages/404') },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
