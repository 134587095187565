import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';

import { apiSlice } from '../services/apiSlice';
import authReducer from './authSlice';
import displayReducer from './displaySlice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const persistConfig = {
  key: 'francheyeStorage',
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const getStore = () =>
  configureStore({
    reducer: {
      [apiSlice.reducerPath]: apiSlice.reducer,
      auth: persistedAuthReducer,
      display: displayReducer,
    },

    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware),

    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),

    devTools: import.meta.env.DEV,
  });

export const store = getStore();

export const persistor = persistStore(store);
